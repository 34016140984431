<template>
  <div class="container">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('record.finishedText')" :loading-text="$t('record.loadingText')" @load="onLoad" v-if="!visible">
      <div class="main" v-for="(item,index) in list" :key="index">
        <img class="bg-img" src="../../../assets/images/record-bg.png" />
        <div class="content">
          <div class="content-box">
            <div class="jfy-btn">
              <div class="left">
                <div class="card-num">{{$t('record.cardNum')}}{{item.cardNo}}</div>
                <div class="time">{{item.activeTime}}</div>
              </div>
              <div class="right">
                <span class="yuan">{{$t('record.yuan1')}}</span>
                <span class="num">{{item.amount}}</span>
                <span class="yuan">{{$t('record.yuan2')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="nodata" v-else>
      <img class="nodata-img" src="../../../assets/images/nodata.png" />
      <div class="nodata-txt">{{$t('record.nodataTxt')}}</div>
    </div>
  </div>
</template>
<script>
import { bindGiftCardList } from '@/api/giftCard'
import { getEnv, initIosAndAndroidEnv, initIosAndAndroidCallBack } from "../../../utils/index"
export default {

  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      visible: false,
      pageNum: 1,
      pageSize: 10
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let ENV = getEnv()
      if (ENV === 'ios' || ENV === 'andriod') {
        // 清除绑卡记录右侧按钮
        initIosAndAndroidEnv(ENV, "navigation/configNavMenu", "deleteBindRecord-20220811", {
          "items": []
        })
        initIosAndAndroidEnv(ENV, "navigation/configNavTitle", "cardRecord-20220811", {
          "title": this.$t('record.envTitle'),
          "color": "#000000"
        })
      }
    },
    onLoad () {
      this.loading = true
      bindGiftCardList({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.success) {
          this.visible = true
          this.loading = false
          this.list = this.list.concat((res.data && res.data.result) || [])
          if (this.list.length) {
            this.visible = false
          } else {
            this.visible = true
          }
          if (res.data) {
            if (this.list.length >= res.data.total) {
              this.finished = true
            } else {
              this.pageNum++
            }
          }
        } else {
          this.loading = false
          this.finished = true
        }
      }).catch(err => {
        this.loading = false
        this.finished = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .container {
    // box-sizing: border-box;
    padding: 16px;
    // height: 100vh;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #f7f9fa;
    .main {
      position: relative;
      .bg-img {
        box-shadow: 0px 2px 4px rgba($color: #d9d9e5, $alpha: 0.5);
        border-radius: 12px;
        width: 100%;
        height: 100%;
      }
      .content {
        // height: 100%;
        padding: 0 20px;
        width: 100%;
        top: 0;
        position: absolute;
        // background-color: aquamarine;
        .content-box {
          // position: relative;
          // top: -50%;
          .jfy-btn {
            // padding-top: 16px;
            height: 110px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: center;
            .left {
              .card-num {
                text-align: left;
                color: #020a19;
                font-weight: bold;
                font-size: 16px;
              }
              .time {
                text-align: left;
                margin-top: 8px;
                color: #a5a7ad;
                font-size: 13px;
              }
            }
            .right {
              color: #222222;
              font-size: 32px;
              // display: flex;
              // align-items: center;
              font-weight: bold;
              .yuan {
                font-weight: bold;
                padding-left: 4px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .main:not(:first-child) {
      margin-top: 8px;
    }
    .nodata {
      margin-top: 100px;
      .nodata-img {
        width: 66%;
        height: 20%;
      }
      .nodata-txt {
        color: #999999;
        font-size: 12px;
        margin-top: 20px;
      }
    }
  }
</style>